<template>
  <div>
    <el-dialog
      width="460px"
      center
      :title="title"
	  :visible.sync="visible"
      @closed="onClose"
    >
    <div class="content">
		<span>请先前往个人中心进行实名认证</span>
	</div>
	<div slot="footer" class="dialog-footer">
	  <el-button @click="onClose">取 消</el-button>
	  <el-button type="primary" @click="goto(linkPath)" >立即前往</el-button>
	</div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'RealNameDialog',
  props: {
	dialogVisible: {
	    type: Boolean,
	    required: true
	},
    message: {
      type: String,
      default: '请先前往个人中心进行实名认证'
    },
    title: {
      type: String,
      default: '提示'
    },
	linkPath: {
	  type: String,
	  default: '/Mine/RealName'
	}
  },
  data () {
    return {
		visible: false
    }
  },
  watch: {
    value: {
      handler (newVal, oldVal) {
        this.selectType = newVal;
      },
      immediate: true //第一次进来触发
    },
  },
  created () {
    this.visible = true
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      console.log("init choose..")
    },
    goto (path) {
      this.$router.push({
        path: path,
        query: {}
      });
    },
    // 关闭弹框时触发的事件
    onClose () {
      this.$emit('close')
    }
  }
}
</script>

<style lang='scss' scoped>

</style>
