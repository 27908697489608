<template>
	<div class="">
		<div class="ObligeeWrap serviceMain" v-if="tabIdx=='tab1'">
			<div class="searchBar">
				<div class="tittle"> 权利人管理 </div>
				<div class="rightForm">
					<div @click="clickTab('tab2')">
						<el-button type="primary">新增个人权利人</el-button>
					</div>
					<div @click="clickTab('tab3')">
						<el-button type="primary">新增企业权利人</el-button>
					</div>
				</div>
			</div>

			<el-table :data="tableData" :cell-class-name="tabelCellClass" style="width: 100%">
				<el-table-column label="个人/企业" width="180">
					<template slot-scope="scope">
						<span v-if="scope.row.obligeeType == '1'">个人</span>
						<span v-else-if="scope.row.obligeeType == '2'">企业</span>
					</template>
				</el-table-column>
				<el-table-column label="权利人">
					<template slot-scope="scope">
						<span v-if="scope.row.obligeeType == '1'">{{scope.row.userName}}</span>
						<span v-else-if="scope.row.obligeeType == '2'">{{scope.row.companyName}}</span>
					</template>
				</el-table-column>
		
				<!-- <el-table-column prop="userName" label="权利人" width="180">
				</el-table-column> -->
				<el-table-column prop="address" label="地址">
					<template slot-scope="scope">
						<span>{{scope.row.province}}-{{scope.row.city}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="expireDate" :formatter="dateFormatter" label="证件有效期" width="180">
				</el-table-column>
				<el-table-column label="操作" width="100">
					<template>
						<el-button type="text" size="small" disabled="disabled">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background layout="prev, pager, next" :total="pagination.total" :current-page.sync="pagination.page"
			 :page-size.sync="pagination.pageSize" @size-change="sizeChange" @current-change="currentChange">
			</el-pagination>
			<real-name-dialog
			v-if="dialogVisible"
					 @close="onDialogClose"
					 ></real-name-dialog>
		</div>
		<div v-if="tabIdx=='tab2'">
			<add-personal @set-tab-idx="setTabIdx" ></add-personal>
		</div>
		<div v-if="tabIdx=='tab3'">
			<add-company @set-tab-idx="setTabIdx"></add-company>
		</div>
		
	</div>
	
</template>

<script>
	import AddCompany from './components/AddCompany'
	import AddPersonal from './components/AddPersonal'
	import RealNameDialog from '@/components/common/RealNameDialog'
	import {
		queryObligeeList
	} from '@/api/ObligeeService'
	export default {
		name: 'Obligee',
		components: {
			AddCompany,
			AddPersonal,
			RealNameDialog
		},
		data() {

			return {
				tabIdx: 'tab1',
				dialogVisible: false,
				pagination: {
					page: 1,
					pageSize: 10,
					total:0
				},
				tableData: []
			}
		},
		created() {
			this.fetchData();
		},
		methods: {
			setTabIdx(tabIdx) {
				this.tabIdx = tabIdx
			},
			clickTab(tabIdx) {
				if(tabIdx != 'tab1' && this.pagination.total <= 0){
					this.dialogVisible = true;
					return;
				}
				this.tabIdx = tabIdx
			},
			 // 日期格式化
			dateFormatter (row, column) {
				if(!row.expireDate){
					return '';
				}
				return this.dayjs(row.expireDate*1000).format("YYYY-MM-DD");
			},
			onTabelRowClick(row) {
			},
			onDialogClose(){
			  this.dialogVisible=false;
			},
			tabelCellClass({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				if (column.property == 'certificate') {
					return 'certificateCell'
				}
			},
			fetchData(param) {
				let that = this;
				queryObligeeList(this.pagination.page, this.pagination.pageSize, this.form).then(({
					data
				}) => {
					console.log("权利人接口数据",data);
					that.tableData = data.list;
					that.pagination.total = data.total/1;
				});
			},
			sizeChange(pageSize) {
				this.pagination.pageSize = pageSize;
				this.pagination.page = 1;
				this.fetchData()
			},
			currentChange(pageNum) {
				this.pagination.page = pageNum;
				// 更新表格数据
				this.fetchData()
			},
		}
	}
</script>

<style lang='scss' scoped>
	@import '../../../assets/css/mine.scss';
</style>
