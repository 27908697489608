<template>
	<div class="container-right">
		<div class="conCompany">
			<div class="formCompany" v-if="!isSuccess">
				<div class="right-SecondTitle">
					<div class="h4">添加企业权利人</div>
					<a @click="back"> 返回上一级 </a>
					<div class="line"></div>
				</div>
				<div class="MainStepsForm">
					<div class="formWrap fl">
						<el-form ref="form" :model="form" :rules="rules" label-width="180px">
							<!-- <el-form-item label="法人姓名" prop="name">
							<el-input v-model="form.userName"></el-input>
						</el-form-item> -->
						<el-form-item label="企业名称" prop="companyName">
							<el-input v-model="form.companyName" placeholder="请输入企业名称"></el-input>
						</el-form-item>
						<el-form-item label="证件类型" prop="identityType">
							<el-select v-model="identityName" @change="selectChanged">
								<el-option v-for="item in company" :key="item.id" :lable="item.id" :value="item.identityName"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="证件号" prop="uniqueCode">
							<el-input v-model="form.uniqueCode" placeholder="请输入证件号"></el-input>
						</el-form-item>
						<el-form-item label="证件是否长期有效" prop="longValid">
							<el-radio-group v-model="form.longValid" @change="changeLongValid">
								<el-radio label="1">是</el-radio>
								<el-radio label="2">否</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="证件有效时间" prop="startDate">
							<el-col :span="11">
								<el-date-picker type="date" placeholder="开始日期" format="yyyy-MM-dd" value-format="timestamp" v-model="form.startDate"
								 style="width: 100%;"></el-date-picker>
							</el-col>
							<el-col :span="2" v-if="this.form.longValid == '2'" style="text-align: center;">-</el-col>
							<el-col :span="11" v-if="this.form.longValid == '2'">
								<el-date-picker type="date" placeholder="截止日期" format="yyyy-MM-dd" value-format="timestamp" v-model="form.expireDate"
								 style="width: 100%;"></el-date-picker>
							</el-col>
						</el-form-item>
						<el-form-item label="国家省市" prop="province" :rules="[{ required: true, validator: valiCity, trigger: 'blur' }]">
							<china-area-data @change="cityChange"></china-area-data>
						</el-form-item>
						<!-- <el-form-item label="详细地址" prop="address">
							<el-input v-model="form.address"></el-input>
						</el-form-item> -->
						<el-form-item label="上传证件照片" prop="additionalProp1" :rules="[{ required: true, validator: valiIcon, trigger: 'blur' }]" >
							<div class="uploadBusinessLicense" >
								<el-upload class="Uploader fl"  :action="uploadUrl"  :headers="uploadHeaders" :show-file-list="false" :on-success="uploadSuccess"
								 :before-upload="uploadBefore">
									<img v-if="form.identityPicture.additionalProp1" :src="form.identityPicture.additionalProp1" class="avatar">
									<i v-else class="el-icon-plus avatar-uploader-icon"></i>
								</el-upload>
								<div class="clear"></div>
							</div>
							<div class="blockReminder">
								<div class="h3">上传证件要求：</div>
								<div class="description" slot="description">
									1、上传的证件内容必须清晰可见<br>
									2、作者名称和证件号码与提交的证件信息一致<br>
									3、要求不得涂改，需确保四边角完整，水印、文字、图片、证件号码等清晰可辨<br>
									4、须小于5MB，且为JPG格式，尺寸过小不宜上传(推荐800*600以上）<br>
									5、证件在有效期之内
								</div>
							</div>
							<div class="exampleWrap">
								上传证件示例：
								<div class="exampleimg"></div>
							</div>
						</el-form-item>
						<el-form-item label="上传证明材料" prop="additionalProp2">
							<div class="uploadBusinessLicense" >
								<el-upload class="Uploader2 fl"  :action="uploadUrl"  :headers="uploadHeaders" :show-file-list="false" :on-success="uploadSuccess2"
								 :before-upload="uploadBefore2">
									<img v-if="form.identityPicture.additionalProp2" :src="form.identityPicture.additionalProp2" class="avatar">
									<i v-else class="el-icon-plus avatar-uploader-icon"></i>
								</el-upload>
								<div class="clear"></div>
							</div>
							<!-- <div class="uploadBusinessLicense" >
								<el-upload class="Uploader2 fl" :action="uploadUrl"  :headers="uploadHeaders" :on-success="uploadSuccess2"
								 :before-upload="uploadBefore2">
									<div class="commonUploadInner">
										<i class="iconfont iconUpload">&#xe647;</i>
										<div class="el-upload__text">
											<div>将文件拖到此处，或<em>点击上传</em></div>
										</div>
									</div>
								</el-upload>
								<div class="clear"></div>
							</div> -->
							<div class="blockReminder">
								<div class="description" slot="description">
									<a href="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/agent-contract-wt.docx" download="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/agent-contract-wt.docx">委托代理合同附件</a>点击下载后，加盖单位公章，法定代表人签字后拍照上传。
								</div>
							</div>
						</el-form-item>

						<el-form-item class="submitRow">
							<el-button type="primary" @click="onSubmit('form')">确认提交</el-button>
						</el-form-item>
						</el-form>
					</div>
					<div class="clear"></div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import {
		createObligee
	} from '@/api/ObligeeService'
	import ChinaAreaData from '@/components/common/ChinaAreaData'
	export default {
		name: 'AddCompany',

		data() {
			return {
				isSuccess: false,
				form: {
					userName: '',
					obligeeType: '2',
					identityPicture: {
						additionalProp1: '',
						additionalProp2: '',
					},
					companyName: null,
					uniqueCode: '',
					address: '',
					identityType: 2,
					longValid: "2",
					province: '',
					city: '',
				},
				uploadUrl: `${process.env.VUE_APP_BASEURL}/v1/ins/file/upload?path=exist/evidence/files/`,
				uploadHeaders: {
					'Authorization': this.$store.getters.token,
					'Product-Mode': "READ",
					'Terminal-Code': "IPCHAIN2" + this.$store.getters.terminalCode,
					'Terminal-Type': "WEB",
					'Merchant-Id': "2"
				},
				rules: {
					// userName: [{
					// 		required: true,
					// 		message: '请输入法人姓名',
					// 		trigger: 'blur'
					// 	},
					// 	{
					// 		min: 2,
					// 		max: 50,
					// 		message: '长度在 2 到 50 个字符',
					// 		trigger: 'blur'
					// 	}
					// ],
					// icon: [{
					// 	required: true,validator: this.valiIcon, trigger: 'change'
					// }],
					companyName: [{
						required: true,
						message: '请输入企业名称',
						trigger: 'blur'
					}],
					identityType: [{
						required: true,
						message: '请选择证件类型',
						trigger: 'blur'
					}],
					uniqueCode: [{
						required: true,
						message: '请输入证件号',
						trigger: 'blur'
					}],
					longValid: [{
						required: true,
					}],

					startDate: [{
						type: 'date',
						required: true,
						message: '请选择证件有效期',
						trigger: 'blur'
					}],
					expireDate: [{
						type: 'date',
						required: true,
						message: '请选择证件有效期',
						trigger: 'blur'
					}],
					address: [{
						required: true,
						message: '请输入公司地址',
						trigger: 'blur'
					}]
				},
				imageUrl: '',

				company: [
					{
						"id": 2,
						"orders": 1,
						"parentId": 2,
						"identityName": "营业执照"
					},
					{
						"id": 3,
						"orders": 2,
						"parentId": 2,
						"identityName": "企业法人营业执照"
					},
					{
						"id": 4,
						"orders": 3,
						"parentId": 2,
						"identityName": "组织机构代码证书"
					},
					{
						"id": 5,
						"orders": 4,
						"parentId": 2,
						"identityName": "事业单位法人证书"
					},
					{
						"id": 6,
						"orders": 5,
						"parentId": 2,
						"identityName": "社团法人证书"
					},
					{
						"id": 12,
						"orders": 6,
						"parentId": 2,
						"identityName": "社会统一信用代码证"
					},
					{
						"id": 7,
						"orders": 7,
						"parentId": 2,
						"identityName": "其他有效证件"
					}
				],
				identityName: '营业执照',
			}
		},
		components: {
			ChinaAreaData
		},
		methods: {
			back() {
				this.$emit('set-tab-idx', 'tab1')
			},
			valiIcon(rule, value, callback){
					if (!this.form.identityPicture.additionalProp1) {
					  callback(new Error('请上传证件照片'));
					  return; 
					}
					callback();
			},
			valiCity(rule, value, callback){ 
					if (!this.form.province) {
					  callback(new Error('请选择省份'));
					  return;
					}
					if (!this.form.city) {
					  callback(new Error('请选择城市'));
					  return;
					}
					// if (!this.form.area) {
					//   callback(new Error('请选择区县'));
					//   return;
					// }
					callback();
			},
			cityChange(data) {
				// console.log(data);
				if(data.laber == 'province'){
					this.form.province = data.value;
				}else if(data.laber == 'city'){
					this.form.city = data.value;
				}
				// this.form.area = data.selectData.area.name;
			},
			onSubmit(formName) {
				this.$refs[formName].validate((valid) => {
				          if (valid) {
				            this.form.startDate = this.form.startDate / 1000;
				            this.form.expireDate = this.form.expireDate / 1000;
							this.startDateText = this.form.startDate * 1000;
							this.expireDateText = this.form.expireDate * 1000;
				            let that = this;
				            createObligee(this.form).then((data) => {
				            	that.$emit('set-tab-idx', 'tab1');
				            	that.$parent.fetchData();
				            }).catch(e => {
				            	this.$message.error('服务请求失败，请稍后重试!');
				            });
				          } else {
				            console.log('error submit!!');
				            return false;
				          }
				});
			},
			uploadSuccess(res, file) {
				this.form.identityPicture.additionalProp1 = res.downloadUrl;
			},
			uploadBefore(file) {
				console.log(file);
				const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 5;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 5MB!');
				}
				return isJPG && isLt2M;
			},
			uploadSuccess2(res, file) {
				this.form.identityPicture.additionalProp2 = res.downloadUrl;
			},
			uploadBefore2(file) {
				console.log(file);
				const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 5;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 5MB!');
				}
				return isJPG && isLt2M;
			},
			// uploadSuccess2(res, file) {
			// 	this.form.supportingMaterial.push(res);
			// },
			// uploadBefore2(file) {
			// 	// console.log(file);
			// 	// const isDocx = file.type === 'docx';
			// 	// // const isLt2M = file.size / 1024 / 1024 < 5;
			// 	// if (!isDocx) {
			// 	// 	this.$message.error('上传文件只能是docx格式');
			// 	// }
			// 	// return isDocx;
			// },

			// 修改证件类型下拉
			selectChanged(value){
				console.log(value);
				if(value == "营业执照"){
					this.form.identityType = 2
				}else if(value == "企业法人营业执照"){
					this.form.identityType = 3
				}else if(value == "组织机构代码证书"){
					this.form.identityType = 4
				}else if(value == "事业单位法人证书"){
					this.form.identityType = 5
				}else if(value == "社团法人证书"){
					this.form.identityType = 6
				}else if(value == "社会统一信用代码证"){
					this.form.identityType = 12
				}else if(value == "其他有效证件"){
					this.form.identityType = 7
				}
			},
			// 改变证件是否有效期单选
			changeLongValid(value){
				// console.log(value);
				this.form.longValid = value
			},
		}
	}
</script>

<style lang='scss' scoped>
	@import "../../../../assets/css/inputForm.scss";
	@import "../../../../assets/css/mine.scss";


	.formCompany {
		.blockReminder {
			background: rgba(255, 231, 186, 0.2);
			border: 1px solid #FFD591;
			padding: 16px;
			// margin-left: -220px;
			width: 480px;
			margin-top: 8px;
			line-height: 1.5;

			.h3 {
				font-size: 16px;
				font-weight: 600;
				color: rgba(0, 18, 22, 0.65);
				margin-bottom: 8px;
			}

			.description {
				font-size: 12px;
				font-weight: 400;
				color: rgba(0, 18, 22, 0.5);
			}
		}

		.exampleimg {
			background: url('https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/exampleCompany.png') no-repeat;
			background-size: auto 98px;
			width: 325px;
			height: 98px;
		}
	}

	.conCompany {
		.MainStepsForm {
			.formWrap {
				width: 568px;
			}
		}

		.uploadBusinessLicense {
			.Uploader,.Uploader2 {
				width: 132px;
				height: 180px;
				background: #F9FAFC;
				border-radius: 4px;
				border: 1px solid #C0CCDA;
				position: relative;
			}

			.Uploader:first-child {
				margin-right: 16px;
			}

			.Uploader:hover {
				border-color: #007DFF;
			}
			
			.Uploader img{
				width: 100%;
				height: auto;
				position: absolute;
				top:50%; 
				left:50%;
				transform: translate(-50%,-50%);
			}

			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				text-align: center;
				line-height: 178px;
			}

			.avatar {
				width: 100%;
			height: auto;
			display: block;
			position: absolute;
			top:50%; 
			left:50%;
			transform: translate(-50%,-50%);
			}
		}

		.exampleWrap {
			margin-top: 8px;
		}

		/deep/.el-col-8 {
			padding-right: 8px;
		}

		/deep/.el-col-8:last-child {
			padding-right: 0px;
		}

		/deep/.el-upload {
			display: block;
		}
	}

	.resultCompany {
		.resultwrap {
			margin-bottom: 16px;
			padding: 24px 24px 24px 80px;
			box-sizing: border-box;
			background: #F5FDFD;
			border-radius: 2px;
			border: 1px solid #D3DCE6;
			position: relative;
			font-size: 16px;
			font-weight: 600;
			color: rgba(0, 18, 22, 0.65);
			line-height: 24px;

			.icon {
				color: #0A4FD1;
				font-size: 40px;
				position: absolute;
				top: 24px;
				left: 24px;
			}
		}

		.BusinessLicensewrap {
			.BusinessLicense {
				width: 132px;
				height: 180px;
				background: #F9FAFC;
				border-radius: 4px;
				border: 1px solid #C0CCDA;
				overflow: hidden;
			}

			.IDcard:first-child {
				margin-right: 16px;
			}
		}

	}
</style>
