<template>
	<div class="container-right">
		<div class="conPersonal">
			<div class="formPersonal" v-if="!isSuccess">
				<div class="right-SecondTitle">
					<div class="h4">添加个人权利人</div>
					<a @click="back"> 返回上一级 </a>
					<div class="line"></div>
				</div>
				<div class="MainStepsForm">
					<div class="formWrap fl">
						<el-form ref="form" :model="form" :rules="rules" label-width="180px">
							<el-form-item label="姓名" prop="userName">
								<el-input v-model="form.userName" placeholder="请输入姓名"></el-input>
							</el-form-item>
							<el-form-item label="证件类型" prop="identityType">
							<el-select v-model="identityName" @change="selectChanged">
								<el-option v-for="item in user" :key="item.id" :lable="item.id" :value="item.identityName"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="证件号" prop="uniqueCode">
							<el-input v-model="form.uniqueCode" placeholder="请输入证件号"></el-input>
						</el-form-item>
						<el-form-item label="证件是否长期有效" prop="longValid">
							<el-radio-group v-model="form.longValid" @change="changeLongValid">
								<el-radio label="1">是</el-radio>
								<el-radio label="2">否</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="证件有效时间" prop="startDate">
							<el-col :span="11">
								<el-date-picker type="date" placeholder="开始日期" format="yyyy-MM-dd" value-format="timestamp" v-model="form.startDate"
								 style="width: 100%;"></el-date-picker>
							</el-col>
							<el-col :span="2" v-if="this.form.longValid == '2'" style="text-align: center;">-</el-col>
							<el-col :span="11" v-if="this.form.longValid == '2'">
								<el-date-picker type="date" placeholder="截止日期" format="yyyy-MM-dd" value-format="timestamp" v-model="form.expireDate"
								 style="width: 100%;"></el-date-picker>
							</el-col>
						</el-form-item>
						<el-form-item label="国家省市" prop="province"  :rules="[{ required: true, validator: valiCity, trigger: 'blur' }]">
							<china-area-data @change="cityChange"></china-area-data>
						</el-form-item>
						<!-- <el-form-item label="详细地址" prop="address">
							<el-input v-model="form.address"></el-input>
						</el-form-item> -->
						<el-form-item label="上传身份证" prop="additionalProp1" :rules="[{ required: true, validator: valiIcon, trigger: 'blur' }]">
							<div class="uploadIDcard"  >
								<el-upload class="Uploader fl" :action="uploadUrl" :headers="uploadHeaders" :show-file-list="false" :on-success="uploadSuccess1"
								 :before-upload="uploadBefore1">
									<img v-if="form.identityPicture.additionalProp1" :src="form.identityPicture.additionalProp1" class="avatar">
									<i v-else class="el-icon-plus avatar-uploader-icon"><p>上传正面</p></i>
								</el-upload>
								<el-upload class="Uploader fl" :action="uploadUrl" :headers="uploadHeaders" :show-file-list="false" :on-success="uploadSuccess2"
								 :before-upload="uploadBefore2">
									<img v-if="form.identityPicture.additionalProp2" :src="form.identityPicture.additionalProp2" class="avatar">
									<i v-else class="el-icon-plus avatar-uploader-icon"><p>上传反面</p></i>
								</el-upload>
								<el-upload class="Uploader fl" :action="uploadUrl" :headers="uploadHeaders" :show-file-list="false" :on-success="uploadSuccess3"
								 :before-upload="uploadBefore3">
									<img v-if="form.identityPicture.additionalProp3" :src="form.identityPicture.additionalProp3" class="avatar">
									<i v-else class="el-icon-plus avatar-uploader-icon"><p>上传手持证件人照片</p></i>
								</el-upload>
								<div class="clear"></div>
							</div>
							<div class="blockReminder">
								<div class="h3">上传证件要求：</div>
								<div class="description" slot="description">
									1、上传的证件内容必须清晰可见<br>
									2、作者名称和证件号码与提交的证件信息一致<br>
									3、要求不得涂改，需确保四边角完整，水印、文字、图片、证件号码等清晰可辨<br>
									4、须小于5MB，且为JPG格式，尺寸过小不宜上传(推荐800*600以上）<br>
									5、证件在有效期之内
								</div>
							</div>
							<div class="exampleWrap">
								上传证件示例：
								<div class="exampleimg"></div>
							</div>
						</el-form-item>
						<el-form-item class="submitRow">
							<el-button type="primary" @click="onSubmit('form')">确认提交</el-button>
						</el-form-item>
						</el-form>
					</div>
					<!-- <div class="blockReminder fl">
						<div class="h3">上传证件要求：</div>
						<div class="description" slot="description">
							1、上传的证件内容必须清晰可见<br>
							2、作者名称和证件号码与提交的证件信息一致<br>
							3、要求不得涂改，需确保四边角完整，水印、文字、图片、证件号码等清晰可辨<br>
							4、须小于5MB，且为JPG格式，尺寸过小不宜上传(推荐800*600以上）<br>
							5、证件在有效期之内
						</div>
					</div> -->
					<div class="clear"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		createObligee
	} from '@/api/ObligeeService'
	import ChinaAreaData from '@/components/common/ChinaAreaData'
	export default {
		name: 'AddPersonal',
		data() {
			return {
				isSuccess: false,
				form: {
					userName: '',
					obligeeType: '1',
					identityPicture: {
						additionalProp1: '',
						additionalProp2: '',
						additionalProp3: ''
					},
					uniqueCode: '',
					startDate: '',
					expireDate: '',
					province: '',
					address: '',
					longValid: "2",
					identityType: 1,
				},
				uploadUrl: `${process.env.VUE_APP_BASEURL}/v1/ins/file/upload?path=exist/evidence/files/`,
				uploadHeaders: {
					'Authorization': this.$store.getters.token,
					'Product-Mode': "READ",
					'Terminal-Code': "IPCHAIN2" + this.$store.getters.terminalCode,
					'Terminal-Type': "WEB",
					'Merchant-Id': "2"
				},
				rules: {
					userName: [{
							required: true,
							message: '请输入姓名',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 50,
							message: '长度在 2 到 50 个字符',
							trigger: 'blur'
						}
					],
					identityType: [{
						required: true,
						message: '请选择证件类型',
						trigger: 'blur'
					}],
					uniqueCode: [{
						required: true,
						message: '请输入证件号码',
						trigger: 'change'
					},
						{
							min: 15,
							max: 18,
							message: '长度在 15 到 18 个字符',
							trigger: 'blur'
						}],
					longValid: [{
						required: true,
					}],
					startDate: [{
						type: 'date',
						required: true,
						message: '请选择开始日期',
						trigger: 'change'
					}],
					expireDate: [{
						type: 'date',
						required: true,
						message: '请选择截止日期',
						trigger: 'change'
					}],
					address: [{
						required: true,
						message: '请输入家庭地址',
						trigger: 'blur'
					}]
				},
				imageUrl: '',

				other: [
					{
						"id": 8,
						"orders": 1,
						"parentId": 3,
						"identityName": "组织机构代码证书/其他证书"
					}
				],
				user: [
					{
						"id": 1,
						"orders": 1,
						"parentId": 1,
						"identityName": "身份证"
					},
					{
						"id": 10,
						"orders": 2,
						"parentId": 1,
						"identityName": "军官证"
					},
					{
						"id": 11,
						"orders": 3,
						"parentId": 1,
						"identityName": "学生证"
					},
					{
						"id": 9,
						"orders": 4,
						"parentId": 1,
						"identityName": "其他证件"
					}
				],
				identityName: '身份证',
				

			}
		},
		components: {
			ChinaAreaData
		},
		methods: {
			back() {
				this.$emit('set-tab-idx', 'tab1')
			},
			valiIcon(rule, value, callback){
					if (!this.form.identityPicture.additionalProp1) {
					  callback(new Error('请上传身份证正面'));
					  return; 
					} 
					if (!this.form.identityPicture.additionalProp2) {
					  callback(new Error('请上传身份证反面'));
					  return; 
					}
					if (!this.form.identityPicture.additionalProp3) {
					  callback(new Error('请上传手持证件人照片'));
					  return;
					}
					callback();
			},
			valiCity(rule, value, callback){ 
					if (!this.form.province) {
					  callback(new Error('请选择省份'));
					  return;
					}
					if (!this.form.city) {
					  callback(new Error('请选择城市'));
					  return;
					}
					// if (!this.form.area) {
					//   callback(new Error('请选择区县'));
					//   return;
					// }
					callback();
			},
			cityChange(data) {
				// console.log(data);
				if(data.laber == 'province'){
					this.form.province = data.value;
				}else if(data.laber == 'city'){
					this.form.city = data.value;
				}
				
				// this.form.area = data.selectData.area.name;
			},
			onSubmit(formName) {
				this.$refs[formName].validate((valid) => {
				          if (valid) {
				            this.form.startDate = this.form.startDate / 1000;
				            this.form.expireDate = this.form.expireDate / 1000;
							this.startDateText = this.form.startDate * 1000;
							this.expireDateText = this.form.expireDate * 1000;
				            let that = this;
				            createObligee(this.form).then((data) => {
								that.$emit('set-tab-idx', 'tab1');
				            	that.$parent.fetchData();
				            }).catch(e => {
				            	this.$message.error('服务请求失败，请稍后重试!');
				            });
				          } else {
				            console.log('error submit!!');
				            return false;
				          }
				});
				
			},
			uploadSuccess1(res, file) {
				this.form.identityPicture.additionalProp1 = res.downloadUrl;
			},
			uploadBefore1(file) {
				const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 5;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 5MB!');
				}
				return isJPG && isLt2M;
			},
			uploadSuccess2(res, file) {
				this.form.identityPicture.additionalProp2 = res.downloadUrl;
			},
			uploadBefore2(file) {
				const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 5;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 5MB!');
				}
				return isJPG && isLt2M;
			},
			uploadSuccess3(res, file) {
				this.form.identityPicture.additionalProp3 = res.downloadUrl;
			},
			uploadBefore3(file) {
				const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 5;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 5MB!');
				}
				return isJPG && isLt2M;
			},


			// 修改证件类型下拉
			selectChanged(value){
				// console.log(value)
				if(value == "身份证"){
					this.form.identityType = 1
				}else if(value == "军官证"){
					this.form.identityType = 10
				}else if(value == "学生证"){
					this.form.identityType = 11
				}else if(value == "其他证件"){
					this.form.identityType = 9
				}
			},
			// 改变证件是否有效期单选
			changeLongValid(value){
				// console.log(value);
				this.form.longValid = value
			},
		}
	}
</script>

<style lang='scss' scoped>
	@import "../../../../assets/css/mine.scss";
	@import "../../../../assets/css/inputForm.scss";

	.right-SecondTitle {
		position: relative;
		padding: 24px 0;

		.h4 {
			font-size: 16px;
			font-weight: 500;
			color: rgba(0, 18, 22, 0.85);
		}

		a {
			position: absolute;
			right: 0;
			top: 26px;
		}

		.line {
			width: 100%;
			height: 2px;
			margin-top: 20px;
			background: rgba(0, 0, 0, 0.02);
		}
	}

	.fl {
		float: left;
	}

	.clear {
		clear: both;
		display: block;
	}

	.formPersonal {
		.blockReminder {
			width: 480px;
			background: rgba(255, 231, 186, 0.2);
			border: 1px solid #FFD591;
			padding: 16px;
			margin-top: 8px;
			line-height: 1.5;

			.h3 {
				font-size: 16px;
				font-weight: 600;
				color: rgba(0, 18, 22, 0.65);
				margin-bottom: 8px;
			}

			.description {
				font-size: 12px;
				font-weight: 400;
				color: rgba(0, 18, 22, 0.5);
			}
		}

		.exampleimg {
			background: url('https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/exampleIDcard.png') no-repeat;
			background-size: auto 48px;
			width: 327px;
			height: 48px;
		}
	}

	.conPersonal {
		.MainStepsForm {
			.formWrap {
				width: 568px;
			}
		}

		.uploadIDcard {
			width: 780px;
			.Uploader {
				width: 180px;
				height: 140px;
				background: #F9FAFC;
				border-radius: 4px;
				border: 1px solid #C0CCDA;
				margin-right: 16px;
				position: relative;
			}

			.Uploader:hover {
				border-color: #007DFF;
			}

			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				text-align: center;
				margin-top: 40px;
				p{
					font-size: 14px;
					line-height: 40px;
				}
			}

			.avatar {
				width: 100%;
				height: auto;
				display: block;
				position: absolute;
				top:50%; 
				left:50%;
				transform: translate(-50%,-50%);
			}
		}

		.exampleWrap {
			margin-top: 8px;
		}

		/deep/.el-col-8 {
			padding-right: 8px;
		}

		/deep/.el-col-8:last-child {
			padding-right: 0px;
		}

		/deep/.el-upload {
			display: block;
		}
	}

	.resultPersonal {
		.resultwrap {
			margin-bottom: 16px;
			padding: 24px 24px 24px 80px;
			box-sizing: border-box;
			background: #F5FDFD;
			border-radius: 2px;
			border: 1px solid #D3DCE6;
			position: relative;
			font-size: 16px;
			font-weight: 600;
			color: rgba(0, 18, 22, 0.65);
			line-height: 24px;

			.icon {
				color: #0A4FD1;
				font-size: 40px;
				position: absolute;
				top: 24px;
				left: 24px;
			}
		}

		.IDcardwrap {
			.IDcard {
				width: 200px;
				height: 140px;
				background: #F9FAFC;
				border-radius: 4px;
				border: 1px solid #C0CCDA;
				overflow: hidden;
			}

			.IDcard:first-child {
				margin-right: 16px;
			}
			
			
			.IDcard img{
				width: 200px;
				height: 140px;
			}
			
		}

	}
</style>
